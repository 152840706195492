import React from 'react';

const Stack = () => {
    const programmingLanguagesAndFrameworksTitle = Array.apply(null, Array(4)).map((_: any, i: number) => <h2 className='text' style={{ animationDuration: '50s' }} key={'title-' + i}>Programming Languages and Frameworks</h2>);
    const programmingLanguagesAndFrameworksContent = Array.apply(null, Array(2)).map((_: any, i: number) => (
        <p className='text' style={{ animationDuration: '50s' }} key={i}>
            #Java #C-Sharp #Cpp #Kotlin #Python
            #Javascript #TypeScript
            #ReactJS #React #Vue #Vue3 #AngularJS #Angular
            #HTML5 #HTML #CSS #SASS
        </p>
    ));

    const testingAndQualityAssuranceTitle = Array.apply(null, Array(4)).map((_: any, i: number) => <h2 className='text' key={'title-' + i}>Testing and Quality Assurance</h2>);
    const testingAndQualityAssuranceContent = Array.apply(null, Array(6)).map((_: any, i: number) => (
        <p className='text' key={i}>
            #XUnit #NSubstitute #Jest #Playwright
        </p>
    ));

    const databaseAndCloudServicesTitle = Array.apply(null, Array(4)).map((_: any, i: number) => <h2 className='text' key={'title-' + i}>Database and Cloud Services</h2>);
    const databaseAndCloudServicesContent = Array.apply(null, Array(4)).map((_: any, i: number) => (
        <p className='text' key={i}>
            #NoSQL #MySQL #PostgreSQL
            #Firebase #AWS #Azure #GoogleCloud
        </p>
    ));

    const CiCdTitle = Array.apply(null, Array(20)).map((_: any, i: number) => <h2 className='text' style={{ animationDuration: '5s' }} key={'title-' + i}>CI/CD</h2>);
    const CiCdContent = Array.apply(null, Array(4)).map((_: any, i: number) => (
        <p className='text' key={i}>
            #OctopusDeploy #TeamCity #GithubActions
        </p>
    ));

    const gameDevelopmentTitle = Array.apply(null, Array(10)).map((_: any, i: number) => <h2 className='text' key={'title-' + i}>Game Development</h2>);
    const gameDevelopmentContent = Array.apply(null, Array(25)).map((_: any, i: number) => (
        <p className='text' key={i}>
            #Unity #Blender
        </p>
    ));

    const toolsTitle = Array.apply(null, Array(20)).map((_: any, i: number) => <h2 className='text' style={{ animationDuration: '5s' }} key={'title-' + i}>Tools</h2>);
    const toolsContent = Array.apply(null, Array(2)).map((_: any, i: number) => (
        <p className='text' style={{ animationDuration: '60s' }} key={i} >
            #Git #GitHub #BitBucket #Jira
            #IntelliJ #Rider.#Eclipse #Atom #VisualStudio #VisualStudioCode
            #Kibana #OpenSearch #Graphana #MSQLServerManagementStudio #SSMS
        </p >
    ));

    return (
        <div className='page' id='stack'>
            <div className='overlap'>
                <h1>
                    Stack
                </h1>
                <a href="mailto:contact@einarvandevelde.com?subject=I want to get in touch!">
                    Get in touch
                </a>
                <p>
                    Click on 'Get in touch' to learn more
                </p>
            </div>
            <div className="content">
                <div className='techstack'>

                    <div className='text-scroll'>
                        {programmingLanguagesAndFrameworksTitle}
                    </div>
                    <div className='text-scroll'>
                        {programmingLanguagesAndFrameworksContent}
                    </div>

                    <div className='text-scroll'>
                        {testingAndQualityAssuranceTitle}
                    </div>
                    <div className='text-scroll'>
                        {testingAndQualityAssuranceContent}
                    </div>

                    <div className='text-scroll'>
                        {databaseAndCloudServicesTitle}
                    </div>
                    <div className='text-scroll'>
                        {databaseAndCloudServicesContent}
                    </div>

                    <div className='text-scroll'>
                        {CiCdTitle}
                    </div>
                    <div className='text-scroll'>
                        {CiCdContent}
                    </div>

                    <div className='text-scroll'>
                        {gameDevelopmentTitle}
                    </div>
                    <div className='text-scroll'>
                        {gameDevelopmentContent}
                    </div>

                    <div className='text-scroll'>
                        {toolsTitle}
                    </div>
                    <div className='text-scroll'>
                        {toolsContent}
                    </div>

                </div>
            </div>
        </div >
    )
}

export { Stack };