import React from 'react';

const Home = () => {
    return (
        <div className='page' id='home'>
            <div className='overlap'>
                <h1>
                    Hi
                </h1>
                <a href="mailto:contact@einarvandevelde.com?subject=I want to get in touch!">
                    Get in touch
                </a>
                <p>
                    Click on 'Get in touch' to learn more
                </p>
            </div>
        </div>
    )
}

export { Home };