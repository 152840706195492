import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Projects = () => {
    let dialog = document.querySelector("dialog");
    let [dialogExternalLink, setDialogExternalLink] = useState("#");

    const setModalOpenCSS = (open: boolean) => {
        if (open) {
            document.body?.classList.add("modal-open", "viewport-lg");
        } else {
            document.body?.classList.remove("modal-open", "viewport-lg");
        }
    }

    const closeModal = () => {
        if (dialog === null) {
            dialog = document.querySelector("dialog");
        }

        setModalOpenCSS(false);
        setDialogExternalLink("#");
        dialog?.close();
    }

    const showModal = (redirectTo: string) => {
        if (dialog === null) {
            dialog = document.querySelector("dialog");
        }

        setModalOpenCSS(true);
        setDialogExternalLink(redirectTo);
        dialog?.showModal();
    }

    return (
        <>
            <dialog id="modal">
                <div className='flex'>
                    <svg fill="var(--font-color-0)" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.311 45.311" width="64px">
                        <path d="M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656 c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635 C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001 c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0 c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578 c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0 c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29 c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z">
                        </path>
                    </svg>
                    <div style={{ marginLeft: '1rem' }}>
                        <p>This link will redirect you to an external website</p>
                        <p>Do you wish to continue?</p>
                    </div>
                </div>
                <div className='flex' style={{ justifyContent: 'flex-end' }}>
                    <button autoFocus onClick={closeModal}>Cancel</button>
                    <Link to={dialogExternalLink} className="button link">Continue</Link>
                </div>
            </dialog>

            <div id="projects" className="page">
                <div className='overlap'>
                    <h1>
                        Proj'
                    </h1>
                    <a href="mailto:contact@einarvandevelde.com?subject=I want to get in touch!">
                        Get in touch
                    </a>
                    <p>
                        Click on 'Get in touch' to learn more
                    </p>
                </div>

                <div className="content">
                    <div className="project">
                        <button onClick={() => showModal("https://portfolio-cusatelli-2023.web.app/")} className="button link">
                            <h2 className='header'>#1</h2>
                            <h3 className='subheader'>
                                Portfolio 2023
                                <svg viewBox="0 0 24 24" width="32px" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: '-1.5rem' }}>
                                    <path stroke="var(--link-color-0)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11">
                                    </path>
                                </svg>
                            </h3>
                        </button>
                        <div className="description">
                            <p>My portfolio website in 2023.</p>
                            <p>A demo website is still available here: <button onClick={() => showModal("https://portfolio-cusatelli-2023.web.app/")} className="button link">portfolio-cusatelli-2023.web.app</button>.</p>
                        </div>
                    </div>
                    <div className="project">
                        <button onClick={() => showModal("https://portfolio-cusatelli.web.app/")} className="button link">
                            <h2 className='header'>#2</h2>
                            <h3 className='subheader'>
                                Portfolio 2021
                                <svg viewBox="0 0 24 24" width="32px" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: '-1.5rem' }}>
                                    <path stroke="var(--link-color-0)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11">
                                    </path>
                                </svg>
                            </h3>
                        </button>
                        <div className="description">
                            <p>My portfolio website in 2021.</p>
                            <p>A demo website is still available here: <button onClick={() => showModal("https://portfolio-cusatelli.web.app/")} className="button link">portfolio-cusatelli.web.app</button>.</p>
                        </div>
                    </div>
                    <div className="project">
                        <button onClick={() => showModal("https://ekens-bygg.web.app/")} className="button link">
                            <h2 className='header'>#3</h2>
                            <h3 className='subheader'>
                                EkensBygg
                                <svg viewBox="0 0 24 24" width="32px" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: '-1.5rem' }}>
                                    <path stroke="var(--link-color-0)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11">
                                    </path>
                                </svg>
                            </h3>
                        </button>
                        <div className="description">
                            <p>Website for a local construction company based in Växjö, Sweden.</p>
                            <p>The business is no longer active, but a demo website is still available here: <button onClick={() => showModal("https://ekens-bygg.web.app/")} className="button link">ekens-bygg.web.app</button>.</p>
                        </div>
                    </div>
                    <div className="project">
                        <button onClick={() => showModal("https://linnea-andersson.web.app/")} className="button link">
                            <h2 className='header'>#4</h2>
                            <h3 className='subheader'>
                                Tolouä el Shäms
                                <svg viewBox="0 0 24 24" width="32px" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: '-1.5rem' }}>
                                    <path stroke="var(--link-color-0)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11">
                                    </path>
                                </svg>
                            </h3>
                        </button>
                        <div className="description">
                            <p>A website for a small kennel breeding Saluki's in Skåne, Sweden.</p>
                            <p>A demo website is available here: <button onClick={() => showModal("https://linnea-andersson.web.app/")} className="button link">linnea-andersson.web.app</button>.</p>
                        </div>
                    </div>
                    <div className="project">
                        <button onClick={() => showModal("https://www.artstation.com/artwork/oAWowB")} className="button link">
                            <h2 className='header'>#5</h2>
                            <h3 className='subheader'>
                                Avatar
                                <svg viewBox="0 0 24 24" width="32px" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: '-1.5rem' }}>
                                    <path stroke="var(--link-color-0)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11">
                                    </path>
                                </svg>
                            </h3>
                        </button>
                        <div className="description">
                            <p>A 3D model avatar, made in Blender 2.81.</p>
                            <p>Images can be found on Artstation: <button onClick={() => showModal("https://www.artstation.com/artwork/oAWowB")} className="button link">https://www.artstation.com/artwork/oAWowB</button>.</p>
                        </div>
                    </div>
                    <div className="project">
                        <button onClick={() => showModal("https://www.artstation.com/artwork/d8gN8e")} className="button link">
                            <h2 className='header'>#6</h2>
                            <h3 className='subheader'>
                                Shamali Karamah al Mahir
                                <svg viewBox="0 0 24 24" width="32px" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: '-1.5rem' }}>
                                    <path stroke="var(--link-color-0)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11">
                                    </path>
                                </svg>
                            </h3>
                        </button>
                        <div className="description">
                            <p>3D model of a Saluki running in the desert, made in Blender 2.81.</p>
                            <p>Images can be found on Artstation: <button onClick={() => showModal("https://www.artstation.com/artwork/d8gN8e")} className="button link">https://www.artstation.com/artwork/d8gN8e</button>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Projects };