import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './pages/Home.tsx';
import { PageLoader } from './components/PageLoader.tsx';
import { Projects } from './pages/Projects.tsx';
import { Stack } from './pages/Stack.tsx';
import { Navmenu } from './components/Navmenu.tsx';
import { Accessibility } from './components/Accessibility.tsx';

function Router() {
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);

    const onPageLoad = () => setLoading(false);
    useEffect(() => {
        window.scrollTo(0, 0);

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, [pathname]);

    if (loading) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <PageLoader />
            </div>
        );
    }

    return (
        <>
            <Accessibility />
            <Navmenu />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/stack" element={<Stack />} />
            </Routes>
        </>
    );
}

export { Router };